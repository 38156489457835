h5 {
    margin-top: 0.75rem;
}

.mainTitle {
    font-size: 1.5rem;
    text-align: center;
}

.timeline {
    color: white;
    margin: 0.5rem 0;
    border-left: 1.5px solid rebeccapurple;
    border-radius: 0.5rem;
}

/* Default vertical layout for small screens */
.timelineEvent {
    padding-left: 1rem;
    position: relative;
    width: auto;
    margin-top: 3rem;
}

/* Timeline dot position */
.timelineEvent::before {
    content: attr(data-year);
    position: absolute;
    top: -2rem;
    width: 15rem;
    /* Width for the rectangle */
    height: 2.5rem;
    /* Height for the rectangle */
    border-radius: 0.5rem;
    /* Rounded corners */
    background-color: rebeccapurple;
    /* No background fill */
    font-size: 0.9rem;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translate(-10%, 0);
    z-index: 1;
}

.timelineEvent h5 {
    font-size: 1.25rem;
    font-weight: bold;
}

.timelineEvent p {
    font-size: 1rem;
}

.timelineEvent small {
    display: block;
    margin-top: 0.5rem;
    color: #6c757d;
}

/* Media Queries for larger screens (tablets, laptops, etc.) */
@media (min-width: 768px) {
    .Card {
        margin-bottom: 0.5rem;
    }

    .timeline {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        border-left: none;
        border-radius: none;
    }

    .timelineEvent {
        /* Align items horizontally */
        width: 48%;
        /* Two items per row */
        margin-bottom: 1rem;
        /* Reduce space between timeline events */
        border-left: none;
        margin-top: 0.5rem;
    }

    /* Position the timeline dots horizontally */
    .timelineEvent::before {
        position: relative;
        left: 40px;
        /* Center the dot horizontally */
        top: 10px;
        /* Position above the card */
        transform: translateX(-20%);
        /* Offset to align with card content */
    }

    .timelineEvent h5 {
        font-size: 1rem;
    }
}