.navbutton {
    margin-right: 1rem;
    /* Adjust margin for spacing */
    padding: 0.5rem 1rem;
    /* Add padding for link content */
    border: 1px solid transparent;
    /* Add border for hover effect */
    border-radius: 4px;
    /* Add border radius for rounded corners */
    color: #fff;
    /* Text color */
    text-decoration: none;
    /* Remove underline */
    transition: all 0.3s ease-in-out;
    /* Add transition for hover effect */
}

.navbutton:hover {
    background-color: rgba(255, 255, 255, 0.1);
    /* Add hover background color */
}

.navbutton>svg {
    margin-right: 0.5rem;
}

.headerContainer {
    img {
        width: 32px;
        border-radius: 50%;
    }
}