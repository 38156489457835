.drumKit {
    .drumContainer {
        text-align: center;
        background-color: #283149;
        border-radius: 1rem;
        width: 80%;

        @media screen and (max-width: 768px) {
            width: 100%;
        }
    }

    h1 {
        font-size: 5rem;
        color: #DBEDF3;
        font-family: "Arvo", cursive;
        text-shadow: 3px 0 #DA0463;

    }

    footer {
        color: #DBEDF3;
        font-family: sans-serif;
    }

    .w {
        background-image: url('../../assets/images/drumkit/tom1.png');
    }

    .a {
        background-image: url('../../assets/images/drumkit/tom2.png');
    }

    .s {
        background-image: url('../../assets/images/drumkit/tom3.png');
    }

    .d {
        background-image: url('../../assets/images/drumkit/tom4.png');
    }

    .j {
        background-image: url('../../assets/images/drumkit/snare.png');
    }

    .k {
        background-image: url('../../assets/images/drumkit/kick.png');
    }

    .l {
        background-image: url('../../assets/images/drumkit/crash.png');
    }

    .set {
        margin: 10% auto;
    }

    .game-over {
        background-color: red;
        opacity: 0.8;
    }

    .pressed {
        box-shadow: 0 3px 4px 0 #DBEDF3;
        opacity: 0.5;
    }

    .red {
        color: red;
    }

    .drum {
        outline: none;
        border: 10px solid #404B69;
        font-size: 2rem;
        font-family: 'Arvo', cursive;
        line-height: 2;
        font-weight: 900;
        color: #0b1e99;
        text-shadow: 3px 0 #DBEDF3;
        border-radius: 15px;
        display: inline-block;
        width: 6rem;
        height: 6rem;
        text-align: center;
        margin: 10px;
        background-color: white;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
    }
}