.mainTitle {
    font-size: 1.5rem;
    text-align: center;
}

.logo {
    width: 10rem;
    align-content: center;

    @media (min-width:576px) {
        margin-left: auto;
    }
}

.titles {
    /* text-align: left; */

    @media (max-width: 425px) {
        /* text-align: center; */
    }
}

.Card {
    margin: 1rem;
    display: flex;
    /* Ensures flexbox is applied */
    flex-direction: row;
    /* Default horizontal layout */
    justify-content: space-between;
    /* Ensures space between titles and logos */

    .titles>h3 {
        position: absolute;
        color: white;
        top: -2rem;
        left: -2.5rem;
        width: 4rem;
        /* Width for the rectangle */
        height: 2.5rem;
        /* Height for the rectangle */
        border-radius: 0.5rem;
        /* Rounded corners */
        background-color: rebeccapurple;
        /* No background fill */
        font-size: 0.9rem;
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: center;
        transform: translate(50%, 0);
        z-index: 1;
    }

    @media (max-width: 576px) {
        flex-direction: column;
        /* Stacks titles and logos vertically */
        justify-content: center;
        /* Center align for mobile */
        align-items: center;
        /* Center both horizontally and vertically */

        .titles>h1,
        h2 {
            text-align: center;
        }

    }
}

h1 {
    font-size: 1.3rem;

    @media (min-width:576px) {
        font-size: x-large;

    }
}

h2 {
    font-size: 1.2rem;
}


/************************ Animation ************************/

.cardFade {
    margin: 2rem 0;
    display: flex;
    opacity: 0;
    transform: translateY(20px);
    /* Slightly move cards down */
    animation: fadeIn 0.8s ease-out forwards;
}

@keyframes fadeIn {
    to {
        opacity: 1;
        transform: translateY(0);
    }
}