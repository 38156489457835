.mainTitle {
    font-size: 1.5rem;
    text-align: center;
}

.hobbiesTitleContainer {
    margin-top: 4rem;
    text-align: center;
}

.bodyContainer {
    margin-top: 4rem;
    text-align: center;

    h2 {
        margin-bottom: 2rem;
    }

    li {
        text-align: center;
        list-style: none;
    }
}

.projectsTitleContainer {
    background-color: #2831490d;
    border-radius: 1rem;
    margin-top: 4rem;
    text-align: center;

    h2 {
        padding-top: 1rem;
        margin: 1.5rem;
        font-family: monospace;
        font-weight: 700;
        font-size: xx-large;
    }

}

.projectsContainer {
    margin-top: 4rem;
    text-align: center;

    h3 {
        font-size: large;
        font-weight: 500;
        font-style: oblique;
        margin: 1.5rem;
    }

    p {
        font-family: monospace;
    }

    img {
        width: 250px;
        height: 250px;
    }

    button {
        margin: 1rem 0;
        padding: 0.5rem 1rem;
        border-radius: 0.5rem;
        background-color: #283149;
        color: white;
        font-weight: 700;
        font-size: medium;
        cursor: pointer;
    }
}

.thumbnailContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.75rem;

    img {
        max-width: 100%;
        height: auto;
    }
}


/**************** Animation ****************/
.hobbiesContainer {
    opacity: 0;
    animation: fadeIn 0.7s ease-in forwards;
    position: relative;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}


.fade {
    animation: fadeInOut 3s ease-in-out infinite;
}

@keyframes fadeInOut {
    0% {
        opacity: 0;
        transform: translateY(-10px);
    }

    20% {
        opacity: 1;
        transform: translateY(0);
    }

    80% {
        opacity: 1;
        transform: translateY(0);
    }

    100% {
        opacity: 0;
        transform: translateY(10px);
    }
}